import { type UIMatch, useMatches } from '@remix-run/react';
import findLast from 'lodash-es/findLast';
import { useIsAuthorized } from './hooks';

export interface AuthRequiredRouteHandle {
  /**
   * Must be true if provided, else (auth not required) should not use this
   */
  authRequired: true;
}

export function AuthRequiredRouteStateProvider({
  children,
}: {
  // render props
  children: (props: { authRequiring?: boolean }) => JSX.Element;
}) {
  const isAuthorized = useIsAuthorized();
  const matches = useMatches();
  // find from last to get deepest route level handle first
  const match = findLast(matches, isAuthRequiredRouteMatch);

  // we're in auth required route & not authorized
  if (match?.handle.authRequired && !isAuthorized) {
    return children({ authRequiring: true });
  }

  return children({});
}

type AuthRequiredRouteMatch = UIMatch<unknown, AuthRequiredRouteHandle>;

function isAuthRequiredRouteMatch(
  value: UIMatch
): value is AuthRequiredRouteMatch {
  if (
    value.handle &&
    typeof value.handle === 'object' &&
    'authRequired' in value.handle &&
    value.handle?.authRequired
  )
    return true;
  return false;
}
