import { Text } from '@setel/portal-ui';
import cn from 'classnames';
import { useRef } from 'react';
import { Link } from '~/components';
import { useContainerLink } from '~/shared';
// images
import noFoodImg from './img/food-not-found.svg';
import fuelImg from './img/suggestion-fuel.svg';
import mesraImg from './img/suggestion-mesra.svg';
import creditCardImg from './img/suggestion-petrol-credit-card.svg';
import scanImg from './img/suggestion-scan.svg';
import shareImg from './img/suggestion-share.svg';
import walletImg from './img/suggestion-wallet.svg';

export const NotFound = () => {
  return (
    <div>
      <Banner />
      <SuggestedPages />
    </div>
  );
};

export const Banner = ({
  title = 'Page not found',
  description = 'Sorry, we’ve searched everywhere but still couldn’t find the page that you’re looking for.',
  className,
}: {
  title?: string;
  description?: string;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'flex justify-center items-center bg-[#F7FAFC] w-full',
        'min-h-[535px] md:min-h-[694px] 2xl:min-h-[706px]',
        className
      )}
    >
      <div className="flex justify-center items-center flex-col text-center w-[286px] md:w-[404px]">
        <img
          decoding="async"
          loading="lazy"
          src={noFoodImg}
          className={cn('w-[209px] md:w-[335px] mb-4 md:mb-[22px] 2xl:mb-8')}
        />
        <h1
          className={cn(
            'text-[#2D333A] font-semibold font-title mb-3',
            'text-[32px] leading-[42px] md:font-bold md:text-[52px] md:leading-[70px]'
          )}
        >
          {title}
        </h1>
        <p className="mb-5 text-[#525B65] text-base font-normal md:text-lg">
          {description}
        </p>
        <Link
          href="/"
          target="_self"
          underlineWhenHover
          className="text-[#00B0FF] text-base font-medium"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

const SUGGESTED_PAGES: PageInfo[] = [
  {
    img: fuelImg,
    description: 'Skip the queue and pay for fuel from your vehicle.',
    link: 'https://www.setel.com/fuel',
  },
  {
    img: mesraImg,
    description: 'Want to collect 3x Mesra points?',
    link: 'https://www.setel.com/mesra-points',
  },
  {
    img: creditCardImg,
    description: 'Credit cards that offer up to 10% petrol cashback.',
    link: 'https://www.setel.com/petrol-credit-card',
  },
  {
    img: walletImg,
    description: 'If it is motorist-related, you can pay with Setel Wallet.',
    link: 'https://www.setel.com/setel-wallet',
  },
  {
    img: shareImg,
    description: 'Pay for your whole family with Setel Share.',
    link: 'https://www.setel.com/setel-share',
  },
  {
    img: scanImg,
    description: 'Scan & pay with Setel at restaurants, workshops, & more!',
    link: 'https://www.setel.com/scan-pay',
  },
];

const SuggestedPages = () => {
  return (
    <div
      className={cn(
        'flex justify-center items-center my-8 mx-5',
        'md:my-[52px] md:mx-[50px]',
        'lg:my-[52px] lg:mx-[100px]',
        'xl:my-[72px] xl:mx-[50px]',
        '2xl:mx-[178px]'
      )}
    >
      <div>
        <div className="flex justify-center items-center">
          <Text
            className={cn(
              'mb-8 text-center text-[#2D333A] font-title font-semibold text-2xl',
              'w-[271px] md:w-auto',
              'md:text-[28px] md:leading-[38px] md:font-semibold'
            )}
          >
            Are you looking for one of these pages?
          </Text>
        </div>
        <div className="flex flex-col items-center px-5 md:flex-none md:grid md:grid-cols-2 xl:grid-cols-3 gap-8">
          {SUGGESTED_PAGES.map((page, index) => (
            <PageCard info={page} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface PageInfo {
  img: string;
  description: string;
  link: string;
}

interface PageCardProps {
  info: PageInfo;
}

const PageCard = ({ info }: PageCardProps) => {
  const excludeRef = useRef<HTMLAnchorElement>(null);
  const { anchorRef, handleClick } = useContainerLink({
    excludeRefs: [excludeRef],
  });

  return (
    <div
      style={{ boxShadow: '0px 20px 28px rgba(0, 0, 0, 0.06)' }}
      className={cn(
        'rounded-2xl w-[335px] md:w-[318px] lg:w-[396px] xl:w-[372px] cursor-pointer'
      )}
      onClick={handleClick}
    >
      <img
        decoding="async"
        loading="lazy"
        src={info.img}
        className="rounded-t-2xl w-[335px] md:w-[318px] lg:w-[396px] xl:w-[372px]"
      />
      <div className="p-6 min-h-[147px] md:min-h-[174px] flex flex-col justify-between">
        <Text className="text-[#2D333A] text-xl font-medium">
          {info.description}
        </Text>
        <Link
          ref={anchorRef}
          href={info.link}
          target="_self"
          underlineWhenHover
          className="text-[#00B0FF] font-medium text-lg"
        >
          Learn more
        </Link>
      </div>
    </div>
  );
};
