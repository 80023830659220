import { type UIMatch, useMatches } from '@remix-run/react';
import findLast from 'lodash-es/findLast';
import { Suspense, lazy } from 'react';
import { ErrorBoundary } from '~/components/ErrorBoundary';

export interface FooterSMSFormRouteHandle {
  footerSMSForm: boolean | { className: string };
}

const FooterSMSForm = lazy(() =>
  import('~/features/Footer/FooterSMSForm').then((m) => ({
    default: m.FooterSMSForm,
  }))
);

export type FooterSMSFormRouteMatch = UIMatch<
  unknown,
  FooterSMSFormRouteHandle
>;

export const RenderFooterSMSForm = () => {
  const matches = useMatches();
  // find from last to get deepest route level handle first
  const match = findLast(matches, isFooterSMSFormRouteMatch);
  if (!match) return null;

  const { footerSMSForm } = match.handle;
  if (!footerSMSForm) return null;

  return (
    <ErrorBoundary fallback={<div hidden />}>
      <Suspense>
        <FooterSMSForm
          className={
            typeof footerSMSForm === 'object' && footerSMSForm.className
              ? footerSMSForm.className
              : ''
          }
        />
      </Suspense>
    </ErrorBoundary>
  );
};

function isFooterSMSFormRouteMatch(
  value: UIMatch
): value is FooterSMSFormRouteMatch {
  return isFooterSMSFormRouteHandle(value.handle);
}

function isFooterSMSFormRouteHandle(
  value: any
): value is FooterSMSFormRouteHandle {
  if (!value || typeof value !== 'object') return false;
  if (!('footerSMSForm' in value)) return false;
  if (
    typeof value?.footerSMSForm !== 'boolean' &&
    typeof value?.footerSMSForm !== 'object'
  ) {
    return false;
  }

  return true;
}
