import slickStyle from 'slick-carousel/slick/slick.css';
import slickThemeStyle from 'slick-carousel/slick/slick-theme.css';
import fontStyle from '~/fonts.css';
import tailwindStyleHref from '~/global.css';

export const globalStyleHrefs = [
  // NOTE order within this array is crucial

  slickStyle,
  slickThemeStyle,
  fontStyle,
];

// Tailwind stylesheet href is exported separately for fine-grain control, usually it should be
// placed as last <link rel="stylesheet"> so its styles can override everything.
export { tailwindStyleHref };
