var parser: DOMParser;

export async function fetchWpFooter(url: string) {
  try {
    const response = await fetch(url, {
      headers: { Accept: 'text/html' },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch WP html: ${response.status} status`);
    }

    // Create an instance of the DOM parser. We do it here instead of top-level to support SSR
    // while maintaining a single parser instance for optimal performance.
    if (!parser) {
      parser = new DOMParser();
    }

    const doc = parser.parseFromString(await response.text(), 'text/html');

    const footerSelector = '[data-elementor-type="footer"]';
    const footerEl = doc.querySelector(footerSelector);
    if (!footerEl) {
      throw new Error(`Cannot find selector from WP html: ${footerSelector}`);
    }

    return new ReadableStream<string>({
      start(controller) {
        // links
        for (let el of doc.querySelectorAll('link[rel="stylesheet"]')) {
          controller.enqueue(el.outerHTML);
        }
        // styles
        for (let el of doc.querySelectorAll('style')) {
          controller.enqueue(el.outerHTML);
        }
        // finally append footer HTML
        controller.enqueue(footerEl.outerHTML);
        controller.close();
      },
    });
  } catch (err) {
    if (err instanceof Error) {
      console.error(err);
    } else {
      console.error(new Error('Unexpected error'), err);
    }
  }
}
