import { useEffect, type ReactNode } from 'react';
import type { HtmlLinkDescriptor } from '@remix-run/react';
import WritableDOMStream from 'writable-dom';
import { ClientOnly } from 'remix-utils/client-only';
import { useQuery } from 'react-query';
import { defaultOnError } from '~/domain/error';
import { fetchWpFooter } from './fetch-wp-footer';

const TAG_NAME = 'setel-footer';

const defaultFallback = (
  <div aria-hidden className="bg-carbon-800 w-full pt-16"></div>
);

export function StreamingFooter() {
  const fallback = defaultFallback;
  return (
    <ClientOnly fallback={fallback}>
      {() => <ShadowDOMFooter fallback={fallback} />}
    </ClientOnly>
  );
}

function ShadowDOMFooter({ fallback }: { fallback: ReactNode }) {
  const { isError, isLoading, isSuccess } = useQuery(
    'wp-footer',
    streamWpFooter,
    {
      staleTime: Infinity,
      retryOnMount: false,
      onError: defaultOnError,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      try {
        updateWPMLAnchors();
      } catch (err) {
        defaultOnError(err);
      }
    }
  }, [isSuccess]);

  return (
    <>
      {isError || isLoading ? fallback : null}

      <footer
        aria-busy={isLoading}
        aria-hidden={isError}
        dangerouslySetInnerHTML={{
          __html: `<${TAG_NAME}></${TAG_NAME}>`,
        }}
      />
    </>
  );
}

const wpFooterUrl: string = '/';

export const links: HtmlLinkDescriptor[] = [
  { href: wpFooterUrl, rel: 'preload', as: 'fetch', crossOrigin: 'anonymous' },
];

async function streamWpFooter() {
  const el = document.querySelector(TAG_NAME);
  if (!el) {
    throw new Error(`<${TAG_NAME}> element not found in DOM.`);
  }

  const s = await fetchWpFooter(wpFooterUrl);
  if (!s) {
    throw new Error(`Received empty response body from footer request.`);
  }

  const root = el.shadowRoot ?? el.attachShadow({ mode: 'open' });
  await s.pipeTo(new WritableDOMStream(root));

  if (!root.childElementCount) {
    throw new Error(`Unable to insert DOM nodes into <${TAG_NAME}>.`);
  }
}

function updateWPMLAnchors() {
  const [footer] = document.getElementsByTagName(TAG_NAME);
  if (!footer) throw new Error(`${TAG_NAME} element not found`);

  const { shadowRoot } = footer;
  if (!shadowRoot) throw new Error(`${TAG_NAME} has no attached ShadowRoot`);

  const WPML_ANCHOR_CLASS = 'wpml-ls-link';

  for (let wpmlAnchor of shadowRoot.querySelectorAll<HTMLAnchorElement>(
    `[class=${WPML_ANCHOR_CLASS}]`
  )) {
    let wpmlUrl = new URL(wpmlAnchor.href, window.location.href);
    let currentUrl = new URL(window.location.href);
    // assign all search queries from WPML link to current url so we wont loose
    // current url's queries
    for (let [k, v] of wpmlUrl.searchParams) {
      currentUrl.searchParams.set(k, v);
    }
    wpmlAnchor.href = currentUrl.search;
  }
}
