import cn from 'classnames';
import { useRouteMatch } from '~/shared/hooks/use-route-match';
import { RootNavigationRoute } from '~/features/Navigation';
import { RenderFooterSMSForm } from './RenderFooterSMSForm';
import { StreamingFooter } from './StreamingFooter';

export { links } from './StreamingFooter';

export const Footer = ({ className }: { className?: string }) => {
  const hideFooterAtSmallScreenRouteMatch = useRouteMatch([
    RootNavigationRoute.DEAL,
  ]);

  return (
    <div
      className={cn(
        'md:block',
        {
          hidden: !!hideFooterAtSmallScreenRouteMatch,
        },
        className
      )}
    >
      <RenderFooterSMSForm />

      <StreamingFooter />
    </div>
  );
};
