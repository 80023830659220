import cn from 'classnames';
import { IconButton } from '~/components';
import { CrossIcon } from '@setel/web-ui';
import { useAnnouncementState } from './useAnnouncementState';
import { RootNavigationRoute } from '~/features/Navigation';
import { useLocation } from '@remix-run/react';

export interface AnnouncementBarProps {
  className?: string;
}

export const AnnouncementBar = ({ className }: AnnouncementBarProps) => {
  const { visible, content, close } = useAnnouncementState();
  const { pathname } = useLocation();

  // hides on user-related pages
  if (pathname.startsWith(RootNavigationRoute.PROFILE)) {
    return null;
  }
  if (!visible || !content) {
    return null;
  }

  return (
    <div className={cn(className, 'bg-brand-600 text-white py-3 z-10')}>
      <div className="relative pl-5 pr-16 sm:px-12">
        <div
          className={cn(
            'announcement-content',
            'font-normal sm:text-center text-sm sm:text-base'
          )}
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <IconButton
          tabIndex={-1}
          aria-label="Close announcement"
          className="absolute text-white top-1/2 right-5 -translate-y-1/2"
          onClick={() => {
            close();
          }}
        >
          <CrossIcon />
        </IconButton>
      </div>
    </div>
  );
};
