import { useCallback, useEffect, useState } from 'react';
import { useAnnouncementHook } from './announcement.queries';
import { useTranslation } from 'react-i18next';

export const useAnnouncementState = () => {
  const { data } = useAnnouncementHook();
  const { i18n } = useTranslation();

  type AnnouncementKeys = 'announcement_en' | 'announcement_ms';
  const announcementText =
    data?.[`announcement_${i18n.language}` as AnnouncementKeys];

  const [visible, setVisible] = useState(Boolean(data?.showAnnouncement));

  useEffect(() => {
    if (data?.showAnnouncement && announcementText) {
      setVisible(true);
    }
  }, [data]);

  const closeAnnouncement = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    close: closeAnnouncement,
    content: announcementText,
  };
};
