import { useQuery } from 'react-query';
import { defaultOnError, throwWPError } from '~/domain/error';
import { CamelizeKeys } from '~/shared';
import { apiClient } from '~/shared/axios';
import { runTimeConfig } from '~/shared/runtime-config';

type AnnouncementResponse = {
  announcement?: string;
  announcement_en?: string;
  announcement_ms?: string;
  announcement_ta?: string;
  announcement_zh_hans?: string;
  announcement_zh_hant?: string;
  show_announcement: boolean;
  appstore_ratings?: string;
  playstore_ratings?: string;
  appstore_ratings_count?: string;
  playstore_ratings_count?: string;
  show_banner?: boolean;
  banner_link?: string;
  banner_image_sm?: string;
  banner_image_md?: string;
  banner_image_lg?: string;
  banner_image_xl?: string;
};

type Announcement = CamelizeKeys<AnnouncementResponse> & {
  announcement_en?: string;
  announcement_ms?: string;
  announcement_ta?: string;
  announcement_zh_hans?: string;
  announcement_zh_hant?: string;
};

export const useAnnouncement = {
  queryKey: 'fetchAnnouncement',
  queryFn: async () => {
    return apiClient
      .get<AnnouncementResponse>('/wp/v2/_/announcement', {
        baseURL: runTimeConfig.WORDPRESS.API_URL,
        public: true,
        withInterfaceHeaders: false,
      })
      .then(
        ({ data }): Announcement => ({
          announcement_en: data.announcement_en,
          announcement_ms: data.announcement_ms,
          announcement_ta: data.announcement_ta,
          announcement_zh_hans: data.announcement_zh_hans,
          announcement_zh_hant: data.announcement_zh_hant,
          showAnnouncement: data.show_announcement,
          appstoreRatings: data.appstore_ratings,
          appstoreRatingsCount: data.appstore_ratings_count,
          playstoreRatings: data.appstore_ratings,
          playstoreRatingsCount: data.playstore_ratings_count,
          showBanner: data.show_banner,
          bannerLink: data.banner_link,
          bannerImageSm: data.banner_image_sm,
          bannerImageMd: data.banner_image_md,
          bannerImageLg: data.banner_image_lg,
          bannerImageXl: data.banner_image_xl,
        })
      )
      .catch(throwWPError);
  },
};

export const useAnnouncementHook = () => {
  return useQuery<Announcement>(
    useAnnouncement.queryKey,
    useAnnouncement.queryFn,
    {
      onError: defaultOnError,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      suspense: true,
      // prevent throwing to error boundary so we can handle in-place
      useErrorBoundary: () => false,
    }
  );
};
